import { Link } from 'gatsby'
import React from 'react'

import SEO from '../components/SEO'
import Layout from '../layouts/Layout'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section id="hero">
      <div className="content">
        <h1>NOT FOUND</h1>
        <p>The page you are looking for does not exist.</p>
        <Link className="button" to="/">
          <h5>Return home</h5>
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
